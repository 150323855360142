import React from 'react'
import Hero from '../../../react4xp/components/Hero'
import Button from '../../../react4xp/components/Button'

function FullViewPart({ title, intro, buttons }) {
  return (
    <Hero
      title={title}
      intro={intro}
      buttons={buttons?.map(button => (
        <Button
          variant={button.variant}
          size="md"
          text={button.text}
          href={button.href}
        />
      ))}
    />
  )
}

export default (props) => <FullViewPart {...props} />
